<template>
  <div class="contact pt-4">
    <div class="container h-100">
      <div class="row h-100 align-items-center pt-4 pb-4">
        <div class="col-12 col-md-6 offset-md-3">
          <div class="card shadow sm p-0 m-0">
            <div class="card-body p-0 m-0">
              <div class="d-block w-100 contact-header p-0 m-0">
                <h5 class="text-center letter-spacing-2">CONTACT US</h5>
              </div>
              <form action="javascript:void(0)" class="row form-contact label-top" method="post">
                <div class="form-group form-floating-label col-12 mt-2 text-start">
                  <input required type="text" v-model="user.email" name="email" id="email" class="form-control top">
                  <label for="email" class="font-weight-bold top">Contact Email <span class="required">*</span></label>
                </div>
                <div class="form-group form-floating-label col-12 mt-4 text-start">
                  <textarea required v-model="user.message" name="message" id="message" rows="4" cols="50" maxlength="1000" class="form-control top"/>
                  <label for="message" class="font-weight-bold top">Message <span class="required">*</span></label>
                  <div class="ps-2">{{ counter }} / 1000</div>
                </div>
                <div class="col-12 mb-2 mt-2">
                  <button type="submit" :disabled="processing" @click="sendMsg" class="btn-branding border-branding btn-block min-width-100">
                    {{ processing ? "Please wait" : "Send" }}
                  </button>
                  <p v-if="showContactConfirmation && validationMessage" class="success-info success-msg text-center">
                    {{ validationMessage }}
                  </p>
                  <div v-if="validationErrors" class="global-error mt-2">
                    <ValidationErrors :errors="validationErrors"/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValidationErrors from "./ValidationErrors"

export default {
  name: 'Contact',
  components: {
    'ValidationErrors': ValidationErrors
  },
  data(){
    return {
      user:{
        email:"",
        message:""
      },
      processing:false,
      validationMessage: '',
      validationErrors: '',
      showContactConfirmation: false,
      counter: 0
    }
  },
  mounted() {
    // Toggle background to be invisible (we want video parallax)
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content-transparent');
      if (main.classList.contains('main-content')) {
        main.classList.remove('main-content');
      }
    }
  },
  beforeDestroy() {
    // Toggle back the background to be visible
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content');
      if (main.classList.contains('main-content-transparent')) {
        main.classList.remove('main-content-transparent');
      }
    }
  },
  methods:{
    async sendMsg(){
      this.processing = true
      await axios.post('api/contact-us',this.user).then(({data})=>{
        this.validationMessage = data.success;
        this.showContactConfirmation = true;
        const timeout = setTimeout(() => {
          this.showContactConfirmation = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 7000);
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // Too many attempts (5, so we get banned for 5 minutes - throttle middleware in api.php)
        if (response && response.status && response.status === 429) {
          this.validationErrors = {'error': ['Too many attempts, please wait 5 minutes and try again']};
        }
      }).finally(()=>{
        this.processing = false
      })
    }
  },
  watch: {
    'user.message': function (val) {
      this.counter = val.length;
    }
  }
}
</script>

<style lang="scss">

.contact .form-contact {
  padding: 10px 20px 10px 20px;
}

.contact .contact-header {
  width: 100%;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(to left, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  h5 {
    color: white;
    border-radius: 20px 20px 0 0;
    padding: .5rem;
    text-transform: uppercase;
    text-shadow: 2px 1px #3c3c3c;
  }
}

.contact .card {
  border: 0 !important;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(4px) !important;
  input {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  textarea {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}
</style>